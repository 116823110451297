@import '../home';

@import '~@mshops-web-components/newsletter-modal/styles/legend/mobile';

@import '~@mshops-components-library/item/src/styles/mobile';
@import '~@mshops-components-library/item/src/styles/legend/v2/mobile';
@import '~@mshops-components-library/common/src/Components/Section/styles/mobile';
@import '~@mshops-components-library/content-list/src/styles/common/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/general/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/legend/mobile';
@import '~@mshops-components-library/slide/src/styles/legend/mobile';
@import '~@mshops-components-library/products-carousel/src/styles/v2/legend-v2/mobile';
@import '~@mshops-components-library/products-gallery/src/styles/legend/mobile';
@import '~@mshops-components-library/categories-carousel/src/styles/legend/mobile';
@import '~@mshops-components-library/slider/src/styles/original/mobile';
@import '~@mshops-components-library/tab-with-items/src/styles/legend/mobile';
@import '~@mshops-components-library/card-action/src/styles/mobile';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.mobile';
@import '~@mshops-components-library/common/src/Components/Button/styles';
@import '~@mshops-components-library/slider/src/styles/base/mobile';
@import '~@mshops-components-library/slide/src/styles/base/mobile';
@import '~@mshops-components-library/secondary-banner/styles/base/mobile';
@import '~@mshops-components-library/section-text/src/styles/base/mobile';
@import '~@mshops-components-library/categories-gallery/src/styles/templates/legend/mobile';
@import '~@mshops-components-library/categories-gallery/src/CategoryLabelImage/styles/templates/legend/mobile';

@import '../../../../components/payment-data/mobile';
@import '../../../../components/badge-carousel/styles/mobile';
@import '../../../../components/site-shopping-info/mobile';
@import '../../../../components/message/styles/mobile';
@import '../../../../components/message/styles/mobile';
@import '../../../../appearance/buyerInfo/styles/mobile';

// Contact Page
@import '../../../../components/contact-form/styles/mobile';
@import '../../../../components/contact-info/styles/mobile';

// How Buy Landing
@import '../../../../components/HowBuyInfo/styles/mobile';

// Cancel Buy Landing
@import '../../../../appearance/cancelBuyInfo/styles/mobile';

// Bank Conditions Landing
@import '../../../../appearance/bankConditionsInfo/styles/mobile';

// LGPD Landing
@import '../../../../appearance/dataPrivacyInfo/styles/mobile';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/mobile';

// Adhesion contracts Landing
@import '../../../../appearance/adhesionContractInfo/styles/mobile';

// Not Found Page for CCP
@import '../../../../components/NotFoundCCP/styles/mobile';

// Protected Purchase Landing
@import '../../../../components/ProtectedPurchaseInfo/styles/mobile';

// Splinter mobile
@import '~@mshops-components-library/splinter-grid/styles/legend/mobile';

.home {
  padding: rem($small-home-padding);

  &.home--legend {
    padding-top: rem($small-header-height-material);

    &.home--with-flash {
      padding-top: rem($small-header-height-material + $flash-component-height);
    }
  }
}

/* Modifiers for flash component and banner components */
.nav-header__banner {
  background-color: $light-gray;
  display: block;
  line-height: 0;
  padding-top: rem($small-header-height);
  width: 100%;
}

.nav-header__banner-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: rem(96px);
  width: 100%;
}

.header-wrapper--with-flash~.nav-header__banner {
  padding-top: rem($small-header-height + $flash-component-height);
}

.home--with-flash {
  padding-top: rem($small-header-height + $flash-component-height);
}

.home--with-banner {
  padding-top: 0;
}

.recommendations>.container {
  margin-bottom: 0;
}

@media (width >=768px) {
  .home--with-flash {
    padding-top: rem($large-header-height + $flash-component-height);
  }
}

.slider .slide__text button {
  background-color: $andes-white;
  border: none !important;

  span {
    color: $andes-black !important;
  }
}

.home.home--legend.home--with-transparency .component-wrapper:first-child {
  margin-top: 0;

  .dragable-component__container {
    border: none;
  }

  .slider.slider--small .slide__image {
    min-height: rem(256px);
    padding-top: rem(96px);
  }

  .slider.slider--medium .slide__image {
    min-height: rem(256px);
    padding-top: rem(96px);
  }

  .hub__boxed-width {
    padding: 0;

    .banner-text {
      margin-top: 0;
    }
  }
}

.section-text__subtitle {
  font-size: rem($font-size-14);
  font-weight: $font-weight-bold;
}

.section-text__title {
  font-size: rem($font-size-18);
  font-weight: $font-weight-regular;
}

.section-text__text {
  font-size: rem($font-size-14);
}

.section-text__row-buttons-actions__btn-action-user {
  font-size: rem($font-size-14);
}

.recommendations .section-header {
  a {
    margin: 0 auto !important;
  }
}

.home.home--legend .ui-item__content {
  flex-wrap: nowrap;
}
